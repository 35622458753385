import { useRef, useState } from 'react';
import { useForm } from 'react-final-form';
import { isObject } from 'underscore';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import classnames from 'classnames';

import { t } from 'bv-i18n';

const getSuggestionValue = (suggestion) => suggestion.description;

const renderSuggestion = (suggestion) => (
  <div>
    {suggestion.description}
  </div>
);

const NationalityView = ({
  input,
  meta: {
    touched,
    error,
    active,
  },
  preloadedNationalities,
  name: inputName,
}) => {
  const form = useForm();

  const [isExpanded, setIsExpanded] = useState(false);
  const [inputValue, setInputValue] = useState(input.value || '');
  const [suggestions, setSuggestions] = useState(preloadedNationalities);
  const inputRef = useRef(null);

  const getSuggestions = (suggestionValue) => {
    const value = suggestionValue.trim().toLowerCase();
    const inputLength = value.length;

    return preloadedNationalities.filter((it) => it
      .description
      .toLowerCase()
      .slice(0, inputLength) === value);
  };

  const onSuggestionsFetchRequested = ({ reason, value }) => {
    if (reason === 'input-changed' && (value.trim().length || !value.length)) {
      if (value === inputRef.current.value) {
        setSuggestions(getSuggestions(value));
      }
    }
  };

  const toggleSuggestions = () => {
    setSuggestions(isExpanded ? [] : preloadedNationalities);
    setIsExpanded(!isExpanded);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const renderInputComponent = (inputProps) => {
    const errorMessage = (touched && !active && error) ? error : '';

    return (
      <div className="bvs-form-group">
        <input {...inputProps} />
        {errorMessage && (
          <span className="bvs-form-error-msg">
            {errorMessage}
          </span>
        )}
      </div>
    );
  };

  return (
    <div className="regpath__nationality-wrapper">
      <Autosuggest
        theme={{
          container: 'regpath__nationality-input-wrapper',
          suggestionsContainer: 'regpath__nationality-input-menu',
          suggestionsContainerOpen: 'regpath__nationality-input-menu--open',
          suggestion: 'regpath__nationality-input-menu-item',
          suggestionHighlighted: 'regpath__nationality-input-menu-item--highlighted',
        }}
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        renderInputComponent={renderInputComponent}
        inputProps={{
          ...input,
          ref: inputRef,
          type: 'text',
          name: inputName,
          placeholder: t('javascript.account_form.nationality'),
          className: classnames('bvs-form-control', {
            error: (touched && !active && error) ? error : '',
          }),
          value: inputValue && isObject(inputValue) ? inputValue.description : inputValue,
          onChange: (_event, { newValue }) => {
            setInputValue(newValue);
            input.onChange(newValue);
            if (!newValue) {
              form.change('nationality', null);
            }
          },
          onClick: toggleSuggestions,
        }}
        onSuggestionSelected={(_, { suggestion, suggestionValue }) => {
          input.onChange(suggestionValue);
          form.change('nationality', suggestion);
          toggleSuggestions();
        }}
        shouldRenderSuggestions={() => true}
      />
      <span className="account-form__select-arrow-down regpath__nationality-arrow" />
    </div>
  );
};

NationalityView.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
    active: PropTypes.bool,
  }).isRequired,
  preloadedNationalities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      description: PropTypes.string,
      isoCode: PropTypes.string,
    }),
  ),
  name: PropTypes.string.isRequired,
};

NationalityView.defaultProps = {
  preloadedNationalities: [],
};

export default NationalityView;
