import PropTypes from 'prop-types';
import classnames from 'classnames';
import { t } from 'bv-i18n';
import WarningModal from './warning_modal';
import showMessage from '../basic_error';
import PlaceHolder from '../field_placeholder';

const AsiaNameView = ({
  showWarning, firstName, lastName, showCardImage, onInputFocus, meta: {
    touched, error, active,
  }, onInputBlur, onYesClick, onNoClick, onInputChange, lengthValidations, focusedField,
}) => {
  const isNotSelected = document.activeElement !== document.getElementById('lastName')
    && document.activeElement !== document.getElementById('firstName');

  const inputFieldClassNames = classnames(
    'bvs-form-control', {
      error: isNotSelected && error && touched,
      active,
      'with-value': !!firstName || !!lastName,
    },
  );

  return (
    <>
      {showWarning && (
        <WarningModal
          firstName={firstName}
          lastName={lastName}
          onYesClick={onYesClick}
          onNoClick={onNoClick}
        />
      )}
      <div className="account-form__fields account-form__fields_2-col">
        <div className="bvs-form-group">
          <input
            type="text"
            className={inputFieldClassNames}
            autoCapitalize="off"
            autoCorrect="off"
            id="lastName"
            name="lastName"
            value={lastName}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
            onChange={onInputChange}
            {...lengthValidations}
          />
          <PlaceHolder label={t('javascript.account_form.last_name')} htmlFor="lastName" />
        </div>
        <div className="bvs-form-group">
          <input
            type="text"
            className={inputFieldClassNames}
            autoCapitalize="off"
            autoCorrect="off"
            id="firstName"
            name="firstName"
            value={firstName}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
            onChange={onInputChange}
            {...lengthValidations}
          />
          <PlaceHolder label={t('javascript.account_form.first_name')} htmlFor="firstName" />
        </div>
        { isNotSelected && showMessage(touched, error) }
      </div>
      { showCardImage && <div className={`regpath__tooltip-image idcard-${focusedField?.toLowerCase()}`} /> }
    </>
  );
};

AsiaNameView.propTypes = {
  showWarning: PropTypes.bool.isRequired,
  showCardImage: PropTypes.bool.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  onInputFocus: PropTypes.func.isRequired,
  focusedField: PropTypes.string,
  onInputBlur: PropTypes.func.isRequired,
  onYesClick: PropTypes.func.isRequired,
  onNoClick: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  lengthValidations: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
};

AsiaNameView.defaultProps = {
  focusedField: null,
};

export default AsiaNameView;
