import PropTypes from 'prop-types';
import classnames from 'classnames';

const Step = ({
  step, currentStep, onStepClick, hasErrorsAfterSubmit,
}) => {
  const isCurrentStep = step.name === currentStep.name;

  const stepClickHandler = () => onStepClick(step);

  const stepClassNames = () => classnames('regpath__step', {
    active: isCurrentStep,
    finished: step.number < currentStep.number,
    error: isCurrentStep && hasErrorsAfterSubmit,
  });

  return (
    <span className={stepClassNames()} onClick={stepClickHandler}>
      <span className="bvs-link regpath__step-number">{step.number}</span>
      {step.title}
    </span>
  );
};

Step.propTypes = {
  step: PropTypes.instanceOf(Object).isRequired,
  currentStep: PropTypes.instanceOf(Object).isRequired,
  onStepClick: PropTypes.func.isRequired,
  hasErrorsAfterSubmit: PropTypes.bool.isRequired,
};

export default Step;
