import { t } from 'bv-i18n';
import { renderBodyText } from 'Registration/helpers/text_utils';

const TermsConditionsGermany = () => (
  <div
    className="bvs-checkbox-card__content terms-conditions-germany"
  >
    {renderBodyText(t('javascript.registration_path.terms_privacy_policy.desc_germany'))}
  </div>
);

export default TermsConditionsGermany;
