import { useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { useForm, useFormState } from 'react-final-form';
import PropTypes from 'prop-types';
import { Card } from 'bv-components';
import { ModalWindowContext } from 'bv-contexts';
import { useScrollToTop } from 'bv-hooks';
import AcquisitionImage from 'Registration/components/form_parts/acquisition_image';
import SubmitButton from 'Registration/components/form_parts/submit_button';
import { getImages } from 'Registration/selectors/registration_configurations';
import AccountExists from 'Registration/components/form_parts/account_exist';
import TermsConditionsGermany from 'Registration/components/form_parts/terms_conditions_germany';
import { getEmailInUse } from 'Registration/helpers/form';
import HCaptcha from 'SharedComponents/hcaptcha';
import FormField from './form_field';

const LastStep = ({
  fields, images, termsGermany,
}) => {
  const form = useForm();
  const { values } = useFormState();
  const emailInUse = getEmailInUse(values);

  const { scrollableRef } = useContext(ModalWindowContext);
  useScrollToTop([scrollableRef.current], scrollableRef.current);

  useEffect(() => {
    if (termsGermany !== null) {
      form.change('terms_privacy_policy', true);
    }
  }, [termsGermany]);

  const groupedFields = fields.reduce((acc, field) => {
    if (field.group) {
      if (!acc[field.group]) acc[field.group] = [];
      acc[field.group].push(field);
    }

    return acc;
  }, []);

  const ungroupedFields = fields.filter((field) => !field.group);

  return (
    <>
      {Object.keys(groupedFields).map((key) => (
        <Card>
          {groupedFields[key].map((field) => (
            <FormField
              key={field.name}
              {...field}
            />
          ))}
        </Card>
      ))}
      {ungroupedFields.map((field) => (
        <FormField
          key={field.name}
          {...field}
        />
      ))}
      {emailInUse && fields.find((f) => f.name === 'email') && (
        <Card className="account-form__email-in-use__container">
          <AccountExists />
        </Card>
      )}
      <HCaptcha />
      <SubmitButton />
      {termsGermany !== null && <TermsConditionsGermany />}
      <AcquisitionImage images={images} />
    </>
  );
};

LastStep.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  fields: PropTypes.arrayOf(Object).isRequired,
  termsGermany: PropTypes.bool,
};

LastStep.defaultProps = {
  termsGermany: null,
};

const mapStateToProps = (state) => ({
  images: getImages(state),
  termsGermany: state.registrationConfigurations.settings.termsBelowSubmitButton,
});

export default connect(mapStateToProps)(LastStep);
