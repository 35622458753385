import { isEmpty } from 'underscore';
import { fetchConfigurations } from 'Registration/ducks/configurations';
import { setCurrentWizard, setCurrentStep } from 'Registration/ducks/form';
import { getWizards } from 'Registration/selectors/registration_configurations';
import { getCurrentJourney } from 'Registration/selectors/registration_form';

const findWizard = (kind, state) => {
  const journey = getWizards(state).find((wizard) => wizard.journey === getCurrentJourney(state));

  return journey?.wizard?.find((wizard) => wizard.kind === kind);
};

/*
  This is actually a thunk that is suitable for being dispatched,
  the reason why we have extracted it here instead of having it into one of
  our ducks is because it mixes actions (and state) from different reducers
*/
export default (kind = 'registration') => async (dispatch, getState) => {
  // Fetch The configs from the server and store them in our state
  await dispatch(fetchConfigurations());

  // Find the wizard we're interested in
  const wizard = findWizard(kind, getState());

  if (isEmpty(wizard) || isEmpty(wizard.steps)) return null;

  // Set the current wizard into the state
  await dispatch(setCurrentWizard(wizard));
  // Set the first step for the current wizard into the state
  return dispatch(setCurrentStep(wizard.steps[0]));
};
