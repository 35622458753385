import { Spinner } from 'bv-components';
import Error from 'Registration/components/error';
import withRegModal from 'Registration/hocs/with_reg_modal';

// Registration consists of many kind of forms each with a modal title
// So differently wrapped
// Simplest thing is to show a PreviewKind while the proper kind is being loaded
// Other solution would be to use 1 only ModalWindow wrapping everything
// And use redux or a method setState propagated via context to alter the title from inside
export default withRegModal('sign_up')(({ loading, currentWizard }) => {
  // Will display the spinner until all the configs have been loaded
  if (loading) return <Spinner />;
  // If after the configs loaded there is not currentWizard assigned we'll display an error
  if (!currentWizard) return <Error />;

  return null;
});
