import { Modal } from 'bv-components';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

const WarningModal = ({
  firstName, lastName, onYesClick, onNoClick,
}) => (
  <Modal
    warning
    title={t('javascript.account_form.check_correct_name')}
    className="regpath__asia-name-modal"
    actions={[
      {
        label: t('javascript.account_form.action_buttons.yes'),
        id: 'yes-btn',
        warning: true,
        onClick: onYesClick,
      },
      {
        label: t('javascript.account_form.action_buttons.no'),
        id: 'no-btn',
        inverse: true,
        warning: true,
        onClick: onNoClick,
      },
    ]}
  >
    <p>{`${t('javascript.account_form.first_name')}: ${firstName}`}</p>
    <p>{`${t('javascript.account_form.last_name')}: ${lastName}`}</p>
  </Modal>
);

WarningModal.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  onYesClick: PropTypes.func.isRequired,
  onNoClick: PropTypes.func.isRequired,
};

export default WarningModal;
