import * as api from 'Registration/api';
import { showErrorMessage } from 'Registration/helpers/modals';
import { omit, isEmpty } from 'underscore';
import { dataLayer } from 'bv-helpers';
import { isHCaptcha, getCaptchaService } from 'bv-services';
import { session as sessionActionDispatchers } from 'redux-action-dispatchers';
import { accountNumber } from 'bv-helpers/session';
import { set } from 'bv-local-storage';
import { removeFormValues } from 'Registration/local_storage';

const { refreshLoggedIn } = sessionActionDispatchers;

const regSource = (values) => (values.identity_provider ? 'one-auth' : values.registration_type);

const captchaService = getCaptchaService({ allowHCaptcha: true });

const refreshHCaptcha = () => {
  if (isHCaptcha) {
    captchaService.refresh();
  }
};

const submitForm = (values, setNextWizard, moveToField, allWizardFields, hasRetried = false) => (
  api.submitForm(values)
    .then(async (response) => {
      if (response.success) {
        if (response.needLocationCheck) {
          set('need_location_check', 1);
        }

        dataLayer.push({
          event: 'evt_registration',
          account_number: accountNumber(),
          reg_source: regSource(values),
        });

        if (values.nonce) {
          api.createPayPalAccount({ nonce: values.nonce });
        }
        setNextWizard();
        refreshLoggedIn();
        removeFormValues();
      } else if (response.retry) {
        if (!hasRetried && captchaService.shouldRetry()) {
          submitForm({ ...values, token: await captchaService.refresh() },
            setNextWizard,
            moveToField,
            allWizardFields,
            true);
        } else {
          showErrorMessage();
        }
        refreshHCaptcha();
      }
    })
    .catch((error) => error.body.then((errors) => {
      // If we get an error on a field, we should move user to that field's step
      const fieldsErrors = omit(errors, ['success', '_error']);

      refreshHCaptcha();

      if (!isEmpty(fieldsErrors) && allWizardFields.includes(Object.keys(fieldsErrors)[0])) {
        moveToField(Object.keys(fieldsErrors)[0]);
        return fieldsErrors;
      }

      /* eslint-disable-next-line no-underscore-dangle */
      return showErrorMessage(errors._error);
    }))
);

export default submitForm;
