import { connect } from 'react-redux';
import { PhoneNumber } from 'registration-common/components';
import {
  getPhoneNumberCountries,
  getDefaultMobileNumberCountry,
} from 'Registration/selectors/registration_configurations';

const mapStateToProps = (state) => ({
  countries: getPhoneNumberCountries(state),
  defaultMobileCountry: getDefaultMobileNumberCountry(state),
});

export default connect(mapStateToProps)(PhoneNumber);
