import { locale } from 'bv-i18n';
import { getCookie } from 'bv';

export const getCountryByCode = (countries, code) => countries.find((c) => (
  c.isoCode.toUpperCase() === code.toUpperCase()
));

const cookieCode = () => {
  const countryCode = getCookie('geo_country_code');
  return countryCode && countryCode.trim() !== 'false' ? countryCode : null;
};

const localeCode = () => locale().split('-')[1].toLowerCase();

export const getDefaultCountryID = (countries, defaultCountrySetting) => {
  const country = getCountryByCode(countries, cookieCode() || localeCode());
  if (country) return country.id;

  if (!defaultCountrySetting) return null;

  const defaultCountry = getCountryByCode(countries, defaultCountrySetting);
  return defaultCountry?.id;
};
