import { t } from 'bv-i18n';
import PropTypes from 'prop-types';

const PayPalButtonView = ({ payPalLoaded }) => (
  <div className={`paypal-signup regpath${payPalLoaded ? '' : ' hidden'}`}>
    <p>{t('sign_up_with')}</p>
    <div id="paypal-button" className="registration-paypal-button" />
    <p className="divider"><span>{t('or')}</span></p>
  </div>
);

PayPalButtonView.propTypes = {
  payPalLoaded: PropTypes.bool.isRequired,
};

export default PayPalButtonView;
