import PropTypes from 'prop-types';
import classnames from 'classnames';
import { escapeInput } from 'Registration/helpers/validations';
import { shouldShowField } from 'Registration/helpers/form';
import Placeholder from './field_placeholder';
import showMessage from './basic_error';

const BasicText = ({
  input,
  label,
  showTo,
  values,
  meta: {
    touched, error, active, submitError, dirtySinceLastSubmit,
  },
}) => {
  if (!shouldShowField(showTo, values)) return null;

  const showError = (touched && error) || (!dirtySinceLastSubmit && submitError);

  const inputFieldClassNames = classnames(
    'bvs-form-control', {
      error: showError,
      active,
      'with-value': input.value,
    },
  );

  const onChange = ({ target }) => input.onChange(escapeInput(target.value));

  return (
    <div className="bvs-form-group">
      <input
        {...input}
        id={input.name}
        type={input.type}
        className={inputFieldClassNames}
        autoCapitalize="off"
        autoCorrect="off"
        onChange={onChange}
      />
      <Placeholder label={label} htmlFor={input.name} />
      { showMessage(showError, error || submitError) }
    </div>
  );
};

BasicText.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  showTo: PropTypes.instanceOf(Object).isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
};

export default BasicText;
