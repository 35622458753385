import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useForm, useFormState, Field } from 'react-final-form';
import classnames from 'classnames';
import { t } from 'bv-i18n';
import PropTypes from 'prop-types';
import { getDefaultGender } from 'Registration/selectors/registration_configurations';
import showMessage from './basic_error';

const getClassName = (value, currentValue) => (
  classnames('bvs-button-chip is-small', { active: value.substr(0, 1).toUpperCase() === currentValue })
);

const radioInput = (name, validate, selectedGender) => (
  <Field
    name="gender"
    type="radio"
    value={name.substr(0, 1).toUpperCase()}
    validate={validate}
  >
    {({ input }) => (
      <label key={name} htmlFor={`account_gender_${name}`} className={getClassName(name, selectedGender)}>
        {t(`javascript.account_form.${name}`)}
        <input
          id={`account_gender_${name}`}
          type="radio"
          value={name.substr(0, 1).toUpperCase()}
          className="account-form__hidden"
          {...input}
        />
      </label>
    )}
  </Field>
);

const Gender = ({ defaultGender, validate }) => {
  const options = ['male', 'female'];
  const form = useForm();
  const { values: { gender }, touched, errors } = useFormState();

  useEffect(() => {
    if (defaultGender && !gender) {
      form.change('gender', defaultGender);
    }
  }, []);

  return (
    <div className="account-form__fields account-form__gender-fields">
      { options.map((opt) => radioInput(opt, validate, gender)) }
      { showMessage(touched.gender, errors.gender) }
    </div>
  );
};

Gender.propTypes = {
  defaultGender: PropTypes.string,
  validate: PropTypes.func.isRequired,
};

Gender.defaultProps = {
  defaultGender: null,
};

const mapStateToProps = (state) => ({
  defaultGender: getDefaultGender(state),
});

export default connect(mapStateToProps)(Gender);
