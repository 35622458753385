import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import Currency from './currency';

const CurrentCurrency = ({ input: { value }, currencies, onClickHandler }) => {
  if (!value) return null;

  const currency = currencies.find((c) => c.id === parseInt(value, 10));
  return (
    <Currency
      {...currency}
      description={t('javascript.account_form.other_title')}
      currentCurrency={value}
      onClickHandler={onClickHandler}
    />
  );
};

CurrentCurrency.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  currencies: PropTypes.arrayOf(Object).isRequired,
  onClickHandler: PropTypes.func.isRequired,
};

export default CurrentCurrency;
