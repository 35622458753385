import { useState } from 'react';
import { useForm } from 'react-final-form';
import PropTypes from 'prop-types';
import { escapeInput } from 'Registration/helpers/validations';
import AsiaNameView from './asia_name_view';

const AsiaNameContainer = ({
  input, meta, validations,
}) => {
  const form = useForm();

  const [firstName, setFirstName] = useState((input?.value?.firstName) || '');
  const [lastName, setLastName] = useState((input?.value?.lastName) || '');
  const [nameCheck, setNameCheck] = useState('');
  const [showCardImage, setShowCardImage] = useState(false);
  const [focusedField, setFocusedField] = useState();
  const [showWarning, setShowWarning] = useState(false);

  const onInputFocus = (event) => {
    setFocusedField(event.target.name);
    setTimeout(() => setShowCardImage(true), 100);
    input.onFocus(event);
  };

  const onYesClick = () => setShowWarning(false);

  const onNoClick = () => {
    setFirstName('');
    setLastName('');
    setShowWarning(false);
  };

  const onInputBlur = () => {
    if (firstName !== '' && firstName === lastName && nameCheck !== firstName + lastName) {
      setShowWarning(true);
      setNameCheck(firstName + lastName);
    }
    setFocusedField(null);
    // Required to allow a possible next button click to be handled, otherwise won't work
    setTimeout(() => {
      input.onBlur();
      setShowCardImage(false);
    }, 100);
  };

  const onInputChange = ({ target }) => {
    let changedFirstName = firstName;
    let changedLastName = lastName;
    if (target.name === 'firstName') {
      changedFirstName = escapeInput(target.value);
      setFirstName(changedFirstName);
    } else if (target.name === 'lastName') {
      changedLastName = escapeInput(target.value);
      setLastName(changedLastName);
    }

    input.onChange({
      firstName: changedFirstName,
      lastName: changedLastName,
    });

    form.change('first_name', changedFirstName);
    form.change('last_name', changedLastName);
  };

  const lengthValidations = () => {
    const fieldValidations = {};
    const minChars = validations.find((v) => v.type === 'minimumCharacters');
    if (minChars) fieldValidations.minlength = minChars.value;
    const maxChars = validations.find((v) => v.type === 'maximumCharacters');
    if (maxChars) fieldValidations.maxlength = maxChars.value;
    return fieldValidations;
  };

  return (
    <AsiaNameView
      firstName={firstName}
      lastName={lastName}
      showWarning={showWarning}
      showCardImage={showCardImage}
      meta={meta}
      onInputFocus={onInputFocus}
      focusedField={focusedField}
      onInputBlur={onInputBlur}
      onInputChange={onInputChange}
      lengthValidations={lengthValidations}
      onYesClick={onYesClick}
      onNoClick={onNoClick}
    />
  );
};

AsiaNameContainer.propTypes = {
  validations: PropTypes.arrayOf(Object).isRequired,
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
};

export default AsiaNameContainer;
