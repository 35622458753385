import { findAddress, retrieveAddress } from '../api/address_finder';

export const validateAddress = (paypalAddress, loqateValidatorApiKey) => (
  findAddress(
    loqateValidatorApiKey,
    `${paypalAddress.line1} ${paypalAddress.postalCode}`,
    paypalAddress.countryCode,
  )
    .then((results) => {
      if (!results?.Items) return paypalAddress;

      const address = results.Items.find((r) => (
        r.Type === 'Address' && r.Description.indexOf(paypalAddress.city) >= 0
      ));

      if (!address) return paypalAddress;

      return retrieveAddress(loqateValidatorApiKey, address.Id)
        .then(({ Items: items }) => (
          items[0] && items[0].Line1 ? ({
            ...paypalAddress,
            line1: items[0].Line1.replace(items[0].BuildingNumber, '').trim(),
            line2: items[0].BuildingNumber,
          }) : paypalAddress
        ));
    })
);
