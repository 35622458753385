import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Field } from 'react-final-form';
import { getValidationFnForField } from 'Registration/helpers/validations';
import formField from 'Registration/factories/form_field';
import { getCountryIso } from 'Registration/selectors/registration_configurations';

const FormField = ({
  type, validations, dependsOn, ...rest
}) => {
  const country = useSelector(getCountryIso);
  const Component = useMemo(() => formField(type), [type]);
  if (!Component) return null;

  const validator = getValidationFnForField(type, validations);

  if (dependsOn.country) {
    const allowedCountries = dependsOn.country.oneOf || [];
    if (!allowedCountries.includes(country)) return null;
  }

  if (type === 'password') {
    return (
      <Component {...rest} />
    );
  }

  if (type === 'address' || type === 'gender') {
    return (
      <Component
        validations={validations}
        validate={validator}
      />
    );
  }

  return (
    <Field
      {...rest}
      type={type}
      component={Component}
      validate={validator}
      validations={validations}
    />
  );
};

FormField.propTypes = {
  type: PropTypes.string.isRequired,
  validations: PropTypes.arrayOf(Object).isRequired,
  dependsOn: PropTypes.instanceOf(Object),
};

FormField.defaultProps = {
  dependsOn: {},
};

export default FormField;
