import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getNationalities } from 'Registration/selectors/registration_configurations';

import NationalityView from './nationality_view';

const NationalityRedux = ({ preloadedNationalities, ...rest }) => (
  <NationalityView
    name="nationalityLabel"
    preloadedNationalities={preloadedNationalities}
    {...rest}
  />
);

const mapStateToProps = (state) => ({
  preloadedNationalities: getNationalities(state),
});

NationalityRedux.propTypes = {
  preloadedNationalities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      description: PropTypes.string,
      isoCode: PropTypes.string,
    }),
  ),
};

NationalityRedux.defaultProps = {
  preloadedNationalities: [],
};

export default connect(mapStateToProps)(NationalityRedux);
