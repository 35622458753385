import { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'underscore';
import PropTypes from 'prop-types';

import { t } from 'bv-i18n';
import { dataLayer } from 'bv-helpers';
import {
  setCurrentStep as setCurrentStepDucks,
  setNextStep as setNextStepDucks,
  setNextWizard as setNextWizardDucks,
  moveToField as moveToFieldDucks,
  resetForm as resetFormDucks,
} from 'Registration/ducks/form';
import {
  getCurrentStep,
  getWizardSteps,
  getAllWizards,
  getAllWizardFields,
} from 'Registration/selectors/registration_form';
import { getFormValues } from 'Registration/local_storage';
import Registration from './registration';

const RegistrationContainer = ({
  currentStep,
  previousStep,
  setCurrentStep,
  destroyReduxForm,
  resetForm,
  initialValues,
  ...props
}) => {
  useEffect(() => {
    localStorage.removeItem('registration_type');
    return () => {
      resetForm();
      localStorage.removeItem('registration_type');
    };
  }, []);

  useEffect(() => {
    if (currentStep?.number) {
      dataLayer.push({
        event: 'evt_reg_step_active',
        reg_step: currentStep.number,
      });
    }
  }, [currentStep]);

  return currentStep ? (
    <Registration
      currentStep={currentStep}
      previousStep={previousStep}
      onBack={previousStep ? (() => setCurrentStep(previousStep)) : undefined}
      initialValues={initialValues}
      confirmClose={{
        title: t('javascript.account_form.leave_registration'),
        showOnBack: !previousStep,
        btnNoInversed: false,
      }}
      {...props}
    />
  ) : null;
};

RegistrationContainer.propTypes = {
  currentStep: PropTypes.instanceOf(Object),
  previousStep: PropTypes.instanceOf(Object),
  activeSteps: PropTypes.instanceOf(Array).isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  setNextStep: PropTypes.func.isRequired,
  allWizards: PropTypes.instanceOf(Array).isRequired,
  setNextWizard: PropTypes.func.isRequired,
  moveToField: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  allWizardFields: PropTypes.instanceOf(Array).isRequired,
  destroyReduxForm: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object).isRequired,
};

RegistrationContainer.defaultProps = {
  currentStep: null,
  previousStep: null,
};

const mapStateToProps = (state) => {
  const activeSteps = getWizardSteps(state);
  const currentStep = getCurrentStep(state);

  return {
    activeSteps,
    currentStep,
    previousStep: activeSteps[activeSteps.indexOf(currentStep) - 1],
    allWizards: getAllWizards(state),
    allWizardFields: getAllWizardFields(state),
    initialValues: getFormValues(),
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCurrentStep: compose(dispatch, setCurrentStepDucks),
  setNextStep: compose(dispatch, setNextStepDucks),
  setNextWizard: compose(dispatch, setNextWizardDucks),
  moveToField: compose(dispatch, moveToFieldDucks),
  resetForm: compose(dispatch, resetFormDucks),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationContainer);
