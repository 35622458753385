import { t } from 'bv-i18n';
import PropTypes from 'prop-types';

const AcquisitionImage = ({ images }) => (
  images === undefined || images.length === 0 ? null : (
    <img
      className="regpath__acquisition-image"
      src={images[0]}
      alt={t('javascript.account_form.acquisition_image')}
    />
  )
);

AcquisitionImage.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AcquisitionImage;
