import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import FormField from 'Registration/components/form_kinds/registration/form_step/form_field';

const ManualAddress = ({
  renderAddressFinder,
  validations,
  hasAddressFinder,
  addressId,
  fields,
}) => {
  const getFieldValidations = (field) => validations.filter((v) => v.field === field);

  return (
    <>
      {fields?.map((field) => (
        <FormField
          key={field}
          name={field}
          label={t(`account_form.address_mapping.${addressId}.${field}`)}
          type={['county_id', 'state_id'].includes(field) ? 'dropdown' : 'text'}
          validations={getFieldValidations(field)}
        />
      ))}
      {hasAddressFinder && (
        <span
          className="bvs-button-card account-form__link regpath__search-again"
          onClick={renderAddressFinder}
        >
          {t('javascript.account_form.action_buttons.search_again')}
        </span>
      )}
    </>
  );
};

ManualAddress.propTypes = {
  renderAddressFinder: PropTypes.func.isRequired,
  validations: PropTypes.instanceOf(Array).isRequired,
  hasAddressFinder: PropTypes.bool.isRequired,
  fields: PropTypes.arrayOf(Object),
  addressId: PropTypes.string,
};

ManualAddress.defaultProps = {
  fields: [],
  addressId: null,
};

export default ManualAddress;
