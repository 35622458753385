import PropTypes from 'prop-types';
import SelectList from './select_list';
import showMessage from './basic_error';
import { days, months, years } from '../../helpers/birth_data';

const DateOfBirth = ({ input, label, meta: { touched, error } }) => {
  const [year, month = '', day = ''] = (input.value || '').split('-');

  const updateInput = (value) => input.onChange(`${value.year}-${value.month}-${value.day}`);

  const changedValue = ({ target }) => updateInput({
    year,
    month,
    day,
    [target.name]: target.value,
  });

  const showError = (value) => touched && error && !value;

  return (
    <div className="regpath__dob-container">
      <p className="regpath__dob-label">{label}</p>
      <div className="account-form__fields account-form__fields--dob dirty">
        <SelectList value={day} name="day" items={days} onChange={changedValue} error={showError(day)} />
        <SelectList value={month} name="month" items={months} onChange={changedValue} error={showError(month)} />
        <SelectList value={year} name="year" items={years} onChange={changedValue} error={showError(year)} />
        { showMessage(touched, error) }
      </div>
    </div>
  );
};

DateOfBirth.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
};

export default DateOfBirth;
