const fieldWrappers = {
  email: '.account-form__email-in-use',
  dob: '.regpath__dob-container',
  county: '#account_county',
  marketing: '.regpath__marketing-container',
  asia_name: '#lastName',
  identity_card_number: '#id_number',
};

const offsetTop = (el) => el.getBoundingClientRect().top;

export const scrollToFirstError = (errors) => {
  if (!errors || !Object.keys(errors)) return;

  const fieldName = Object.keys(errors)[0];

  const errorField = document.querySelector(fieldWrappers[fieldName])
    || document.querySelector(`input[name="${fieldName}"], select[name="${fieldName}"]`);
  if (errorField) {
    const regModalBody = document.querySelector('.registration-modal .modal_contents_wrapper');
    const position = offsetTop(errorField) + regModalBody.scrollTop - offsetTop(regModalBody);
    regModalBody.scrollTop = Math.max(position - 15, 0);
  }
};

export const shouldShowField = (showTo, values) => (
  (!showTo) || (showTo.field && values && values[showTo.field] === showTo.value)
);

export const getEmailInUse = ({ email, emailsInUse }) => (
  emailsInUse?.includes(email) ? email : null
);
