import { Icon } from 'bv-components';
import PropTypes from 'prop-types';
import { shouldShowField } from 'Registration/helpers/form';

const WarningMessage = ({
  icon, label, showTo, values,
}) => {
  if (!shouldShowField(showTo, values)) return null;

  return (
    <div className="regpath__product-warning-message">
      { icon && <Icon info={icon === 'info'} /> }
      <p>{label}</p>
    </div>
  );
};

WarningMessage.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  showTo: PropTypes.instanceOf(Object).isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
};

export default WarningMessage;
