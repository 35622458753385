import { connect } from 'react-redux';
import { compose } from 'underscore';
import { changeJourney } from 'Registration/ducks/form';
import {
  getCountries,
  getPhoneNumberCountries,
  getCountryZones,
  getRegistrationSettings,
} from 'Registration/selectors/registration_configurations';
import PayPalButtonContainer from './paypal_button_container';

const mapStateToProps = (state) => ({
  countries: getCountries(state),
  phoneNumberCountries: getPhoneNumberCountries(state),
  countryZones: getCountryZones(state),
  settings: getRegistrationSettings(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeJourney: compose(dispatch, changeJourney),
  setShowPayPalButton: ownProps.setShowPayPalButton,
});

export default connect(mapStateToProps, mapDispatchToProps)(PayPalButtonContainer);
