import { get as bvGet, set as bvSet, remove as bvRemove } from 'bv-local-storage';
import { locale } from 'bv-i18n';
import { v as bvVar } from 'bv';

const brandId = bvVar('brandId');

const KEY = 'registrationForm';

const userData = {
  screenResolution: `${window.innerWidth}x${window.innerHeight}`,
  screenOrienation: window.screen.height > window.screen.width ? 'portrait' : 'landscape',
  osLanguage: navigator.language,
};

const getFormValues = () => {
  const storedData = bvGet(KEY) || {};
  const currentRegData = storedData[`${brandId}-${locale()}`] || {};
  return { userData, ...currentRegData };
};

const setFormValues = (values) => {
  const storedData = bvGet(KEY) || {};
  storedData[`${brandId}-${locale()}`] = values;
  bvSet(KEY, storedData);
};

const removeFormValues = () => bvRemove(KEY);

export {
  setFormValues,
  getFormValues,
  removeFormValues,
};
