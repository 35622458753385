import PropTypes from 'prop-types';
import classnames from 'classnames';
import showMessage from '../basic_error';
import Placeholder from '../field_placeholder';
import AddressDropdown from './address_dropdown';

const Dropdown = (props) => {
  const {
    input,
    label,
    meta: {
      touched, error, dirtySinceLastSubmit, submitError,
    },
    options,
  } = props;

  const showError = (touched && error) || (!dirtySinceLastSubmit && submitError);

  if (['country', 'county_id', 'state_id'].includes(input.name)) {
    return <AddressDropdown {...props} />;
  }

  return (
    <div className="bvs-form-group">
      <select
        {...input}
        key={input.name}
        id={`account_${input.name}`}
        value={input.value || ''}
        className={classnames('bvs-form-control active', { error: showError })}
      >
        {!input.value && (
          <option value="">
            {label}
          </option>
        )}
        {options.map((o) => (
          <option key={o.value} id={o.value} value={o.value}>
            {o.description}
          </option>
        ))}
      </select>
      {input.value && (
        <Placeholder
          label={label}
          htmlFor={`account_${input.name}`}
        />
      )}
      {showMessage(touched, error)}
    </div>
  );
};

Dropdown.propTypes = {
  label: PropTypes.string.isRequired,
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  options: PropTypes.arrayOf(Object),
};

Dropdown.defaultProps = {
  options: [],
};

export default Dropdown;
