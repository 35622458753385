import { getCookie } from 'bv';
import { locale } from 'bv-i18n';

export const getDefaultCountryId = (countries, defaultCountryIso) => {
  const getCountryByIsoCode = (isoCode) => countries.find((c) => (
    c.isoCode.toUpperCase() === isoCode?.toUpperCase()
  ));

  if (countries.length === 1) return countries[0].id;

  const currentLocationCountry = getCountryByIsoCode(getCookie('geo_country_code')?.trim());
  if (currentLocationCountry) return currentLocationCountry.id;

  const localeCountry = getCountryByIsoCode(locale().split('-')[1]);
  if (localeCountry) return localeCountry.id;

  const defaultCountry = getCountryByIsoCode(defaultCountryIso);
  if (defaultCountry) return defaultCountry.id;

  return countries[0].id;
};
