import { Modal } from 'bv-components';
import { t } from 'bv-i18n';
import PropTypes from 'prop-types';
import Currency from './currency';

const CurrencyModal = ({ filteredCurrencies, onClose, onCurrencyClick }) => (
  <Modal
    title={t('javascript.account_form.other_title')}
    onCloseClick={onClose}
    className="reg-currency-modal"
    iconClosable
  >
    <div className="account-form__circle-wrapper account-form__circle-wrapper--modal">
      { filteredCurrencies.map((curr) => (
        <Currency {...curr} key={curr.id} onClickHandler={onCurrencyClick} />))}
    </div>
  </Modal>
);

CurrencyModal.propTypes = {
  filteredCurrencies: PropTypes.instanceOf(Object).isRequired,
  onClose: PropTypes.func.isRequired,
  onCurrencyClick: PropTypes.func.isRequired,
};

export default CurrencyModal;
