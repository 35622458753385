import { compose, isEmpty } from 'underscore';
import { getCookie } from 'bv';
import { getWizards, getProductId } from '../selectors/registration_configurations';
import * as api from '../api/fetch_configurations';

export const FETCH_CONFIGURATIONS_INIT = 'registration/FETCH_CONFIGURATIONS_INIT';
export const FETCH_CONFIGURATION_SUCCESS = 'registration/FETCH_CONFIGURATIONS_SUCCESS';

export const fetchConfigurationsInit = () => ({
  type: FETCH_CONFIGURATIONS_INIT,
});

export const fetchConfigurationsSuccess = (configurations) => ({
  type: FETCH_CONFIGURATION_SUCCESS,
  ...configurations,
});

export const fetchConfigurations = () => (dispatch, getState) => {
  if (!isEmpty(getWizards(getState())) && getCookie('product_id') === getProductId(getState())) return Promise.resolve();

  dispatch(fetchConfigurationsInit());

  return api.fetchConfigurations()
    .then(compose(dispatch, fetchConfigurationsSuccess));
};

export const initialState = {
  loading: true,
  countries: [],
  wizards: [],
  phoneNumberCountries: [],
  addressFinderCountries: [],
  currencies: [],
  images: [],
  defaults: {},
  features: {},
  productId: null,
  addressMappings: [],
  passwordRules: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_CONFIGURATIONS_INIT:
      return initialState;

    case FETCH_CONFIGURATION_SUCCESS: {
      const { currencies, defaults } = action;
      const orderedCurrencies = defaults?.currency ? [
        currencies.find((cur) => cur.id === defaults.currency),
        ...currencies.filter((cur) => cur.id !== defaults.currency),
      ] : currencies;

      return {
        ...state,
        ...action,
        currencies: orderedCurrencies.filter(Boolean),
        productId: getCookie('product_id') || null,
        loading: false,
      };
    }

    default:
      return state;
  }
};
