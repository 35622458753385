import { Button, Icon } from 'bv-components';
import { t } from 'bv-i18n';

const NextButton = () => (
  <Button
    className="next regpath__button-next"
    noClass
    type="regpath-button-next"
  >
    <span className="regpath__button-next-text">{t('javascript.account_form.continue')}</span>
    <Icon id="arrow-right" type="regpath-next-arrow" />
  </Button>
);

export default NextButton;
