import { useEffect, useState } from 'react';
import { useForm, FormSpy } from 'react-final-form';
import PropTypes from 'prop-types';
import { Spinner, LiveChat, Modal } from 'bv-components';
import classnames from 'classnames';

import { t } from 'bv-i18n';
import { useOneAuth } from 'bv-hooks';
import { v as bvVar } from 'bv';

import PayPalButton from 'Registration/components/form_parts/paypal_button/paypal_button_redux';
import OneAuthConnect from 'Registration/components/form_parts/one_auth_connect';
import getOauthFormValues from 'Registration/helpers/get_oauth_form_values';
import { scrollToFirstError } from 'Registration/helpers/form';

import SignInUp from 'SharedComponents/sign_in_up';

import GeneralSteps from './general_steps';
import LastStep from './last_step';
import Steps from './progress_bar/steps';
import LoginLink from './login_link';
import CentralisedSignupLoginSteps from './centralised_signup_login/steps';

const FormStepView = (({
  isLastStep, isFirstStep, handleSubmit, submitting, error, errors, values, validating,
  oneAuthParams, countries, resetOneAuthParams, setCountry, features: { paypalSignup }, fields,
  onChange,
}) => {
  const form = useForm();
  const { identity_provider: identityProvider } = values;

  const filteredFields = identityProvider ? fields.filter((field) => field.name !== 'password') : fields;

  const [errorMessage, setErrorMessage] = useState(undefined);
  const [showPayPalButton, setShowPayPalButton] = useState(true);
  const [payPalReady, setPayPalReady] = useState(false);
  const [redirecting, setRedirecting] = useState(false);

  useEffect(() => {
    if (error) setErrorMessage(error);
  }, [error]);

  const [oneAuthEnabled] = useOneAuth();

  useEffect(() => {
    const oauthValues = getOauthFormValues(oneAuthParams, countries);
    if (Object.keys(oauthValues).length) {
      Object.entries(oauthValues)
        .forEach(([field, value]) => {
          form.change(field, value);
          if (field === 'country') setCountry(value);
        });
      resetOneAuthParams();
    }
  }, [countries, oneAuthParams]);

  const loadingClassNames = classnames('account-form__loading', {
    'account-form__submitting-loading': submitting,
  });

  const onCloseClick = () => setErrorMessage(false);

  const paypalStep = isFirstStep && paypalSignup;

  const centralisedSignupLogin = bvVar('isCentralisedSignupLogin');

  if (redirecting) return <Spinner />;

  const handleChange = (formSpy) => {
    const { values: spyValues, submitFailed, dirtySinceLastSubmit } = formSpy;
    onChange(spyValues);
    if (submitFailed && !dirtySinceLastSubmit) {
      scrollToFirstError(errors);
    }
  };

  return (
    <>
      {isFirstStep && centralisedSignupLogin && <SignInUp />}
      {paypalStep && !payPalReady && <Spinner />}
      <div className="registration-path__form-step-container">
        {paypalStep && showPayPalButton && (
          <PayPalButton
            setPayPalReady={setPayPalReady}
            setShowPayPalButton={setShowPayPalButton}
          />
        )}
        <form className="account-form regpath" onSubmit={handleSubmit}>
          <FormSpy
            subscription={{ values: true, submitFailed: true, dirtySinceLastSubmit: true }}
            onChange={handleChange}
          />
          <div className="account-form__inner">
            {(submitting || validating) && (
              <div className={loadingClassNames}><Spinner /></div>
            )}
            {centralisedSignupLogin ? (
              !isFirstStep && <CentralisedSignupLoginSteps />
            ) : (
              <Steps handleSubmit={handleSubmit} />
            )}
            {paypalStep && !showPayPalButton && (
              <p className="account-form__msg--warning">{t('paypal_check_details')}</p>
            )}
            {!isLastStep && (
              <GeneralSteps fields={filteredFields} />
            )}
            {isLastStep && <LastStep fields={filteredFields} />}
          </div>
        </form>
        {isFirstStep && !identityProvider && oneAuthEnabled && (
          <OneAuthConnect setRedirecting={setRedirecting} />
        )}
        {isFirstStep && !centralisedSignupLogin && <LoginLink />}
        <LiveChat className="live-chat live-chat--regpath" location="Registration" />
        {errorMessage && (
          <Modal
            danger
            actions={[
              {
                label: t('close'),
                danger: true,
                onClick: onCloseClick,
              },
            ]}
          >
            <p>{errorMessage}</p>
          </Modal>
        )}
      </div>
    </>
  );
});

FormStepView.propTypes = {
  isLastStep: PropTypes.bool.isRequired,
  isFirstStep: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  validating: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func,
  error: PropTypes.string,
  errors: PropTypes.instanceOf(Object),
  features: PropTypes.instanceOf(Object).isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  oneAuthParams: PropTypes.instanceOf(Object).isRequired,
  countries: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  resetOneAuthParams: PropTypes.func.isRequired,
  setCountry: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  onChange: PropTypes.func.isRequired,
};

FormStepView.defaultProps = {
  handleSubmit: () => { },
  error: undefined,
  errors: {},
};

export default FormStepView;
