import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isEqual } from 'underscore';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { t } from 'bv-i18n';
import { getCaptchaService, getBlackBox } from 'bv-services';
import { scrollToFirstError } from 'Registration/helpers/form';
import {
  mobileNumberValidation,
  emailAvailableValidation,
  emailValidation,
  postCodeValidation,
} from 'Registration/helpers/validations';
import withRegModal from 'Registration/hocs/with_reg_modal';
import * as api from 'Registration/api';
import submitForm from 'Registration/services/submit_form';
import FormStepRedux from './form_step/form_step_redux';

const Registration = ({
  activeSteps, currentStep, setNextStep, setNextWizard,
  allWizards, moveToField, allWizardFields, setShowBack, previousStep,
  initialValues,
}) => {
  const isLastStep = activeSteps.length === currentStep.number;

  const asyncValidations = (values, form) => {
    const validationFns = [
      mobileNumberValidation,
      emailValidation,
      postCodeValidation,
      emailAvailableValidation,
    ];
    return Promise.all(validationFns.map((fn) => fn(values, form)))
      .then((errors) => {
        const validationErrors = Object.assign({}, ...errors);
        if (!isEmpty(validationErrors)) {
          scrollToFirstError(validationErrors);
          return validationErrors;
        }
        return undefined;
      })
      .catch(() => ({
        [FORM_ERROR]: t('errors_something_wrong'),
      }));
  };

  const submitFormHandler = async (values) => {
    const newValues = { ...values };

    if (newValues.io_b_b === undefined) newValues.io_b_b = await getBlackBox();

    const page = await api.fetchPageContent('terms_and_conditions');
    newValues.registration_type = localStorage.getItem('registration_type') || 'manual';

    try {
      newValues.attribution_string = JSON.parse(sessionStorage.getItem('attribution'));
    } catch (e) {
      newValues.attribution_string = {};
    }

    newValues.terms_conditions_version = page ? page.version : null;

    if (newValues.nationality) {
      newValues.nationality = newValues.nationality.id;
    }

    newValues.token = await getCaptchaService({ allowHCaptcha: true }).execute('registration');

    return submitForm(newValues, () => setNextWizard(allWizards), moveToField, allWizardFields);
  };

  const onSubmit = async (values, form) => {
    const errors = await asyncValidations(values, form);
    if (errors) return errors;
    if (isLastStep) return submitFormHandler(values, form);
    return setNextStep();
  };

  useEffect(() => {
    setShowBack(!!previousStep);
  }, [previousStep]);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      initialValuesEqual={isEqual}
      keepDirtyOnReinitialize
    >
      {({ handleSubmit, ...nestedProps }) => (
        <FormStepRedux
          isFirstStep={currentStep.number === 1}
          isLastStep={isLastStep}
          handleSubmit={handleSubmit}
          {...nestedProps}
        />
      )}
    </Form>
  );
};

Registration.propTypes = {
  currentStep: PropTypes.instanceOf(Object).isRequired,
  previousStep: PropTypes.instanceOf(Object).isRequired,
  activeSteps: PropTypes.instanceOf(Array).isRequired,
  setNextStep: PropTypes.func.isRequired,
  allWizards: PropTypes.instanceOf(Array).isRequired,
  setNextWizard: PropTypes.func.isRequired,
  moveToField: PropTypes.func.isRequired,
  allWizardFields: PropTypes.instanceOf(Array).isRequired,
  setShowBack: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object).isRequired,
};

export default withRegModal('sign_up')(Registration);
