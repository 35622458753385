import { Button, Card } from 'bv-components';
import { t } from 'bv-i18n';

const LoginLink = () => (
  <Card className="regpath__login-now">
    {t('already_have_an_account')}
    <Button
      to="/login"
      className="regpath__login-now-button"
      secondary
    >
      {t('login_now')}
    </Button>
  </Card>
);

export default LoginLink;
