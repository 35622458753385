import reactStringReplace from 'react-string-replace';
import { t } from 'bv-i18n';

const { CUSTOMER_CONSENT_URLS } = window.VCSTATE;

const linksToReplace = [{
  pattern: '(terms_conditions_link)',
  tag: 'terms_and_conditions',
  titleKey: 'javascript.registration_path.terms_conditions.title',
  text: t('javascript.registration_path.terms_conditions.title'),
},
{
  pattern: '(privacy_policy_link)',
  tag: 'privacy_policy',
  titleKey: 'javascript.registration_path.privacy_policy.title',
  text: t('javascript.registration_path.privacy_notice.title'),
},
{
  pattern: '(account_documentation_requirements_link)',
  tag: 'documents_we_may_require',
  titleKey: 'javascript.registration_path.documents_we_may_require.title',
  text: t('javascript.registration_path.documents_we_may_require.title'),
}];

export const renderBodyText = (text) => (
  linksToReplace.reduce((acc, value) => (
    reactStringReplace(acc, value.pattern, () => (
      <a className="bvs-text-secondary" href={CUSTOMER_CONSENT_URLS[value.tag]} target="_blank" rel="noreferrer">{value.text}</a>
    ))
  ), text)
);
