import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

const OtherIcon = ({ onClickHandler }) => (
  <div className="account-form__circle-selector">
    <span className="account-form__circle-label">{t('javascript.account_form.other_title')}</span>
    <span className="account-form__circle-symbol" onClick={onClickHandler}>+</span>
  </div>
);

OtherIcon.propTypes = {
  onClickHandler: PropTypes.func.isRequired,
};

export default OtherIcon;
