import { useContext } from 'react';
import { connect } from 'react-redux';
import { useFormState } from 'react-final-form';
import PropTypes from 'prop-types';
import { Card } from 'bv-components';
import { useScrollToTop } from 'bv-hooks';
import { ModalWindowContext } from 'bv-contexts';
import NextButton from 'Registration/components/form_parts/next_button';
import AcquisitionImage from 'Registration/components/form_parts/acquisition_image';
import { getCurrentStep } from 'Registration/selectors/registration_form';
import { getImages } from 'Registration/selectors/registration_configurations';
import { getEmailInUse } from 'Registration/helpers/form';
import AccountExists from 'Registration/components/form_parts/account_exist';
import FormField from './form_field';

const GeneralSteps = ({ fields, images, currentStep }) => {
  const { values } = useFormState();
  const emailInUse = getEmailInUse(values);

  const { scrollableRef } = useContext(ModalWindowContext);
  useScrollToTop([currentStep, scrollableRef.current], scrollableRef.current);

  return (
    <Card>
      {fields.map((field) => (
        <FormField
          key={field.name}
          {...field}
        />
      ))}
      {emailInUse && fields.find((f) => f.name === 'email') && <AccountExists />}
      <NextButton />
      <AcquisitionImage images={images} />
    </Card>
  );
};

GeneralSteps.propTypes = {
  fields: PropTypes.arrayOf(Object).isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentStep: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    fallback: PropTypes.string,
    isFallback: PropTypes.bool,
    number: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  images: getImages(state),
  currentStep: getCurrentStep(state),
});

export default connect(mapStateToProps)(GeneralSteps);
