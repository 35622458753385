import PropTypes from 'prop-types';
import classnames from 'classnames';
import PlaceHolder from './field_placeholder';
import showMessage from './basic_error';

const BasicNumber = ({
  input,
  label,
  meta: {
    touched, error, active,
  },
}) => {
  const inputFieldClassNames = classnames(
    'bvs-form-control', {
      error: error && touched,
      'with-value': input.value && input.value !== '',
      active,
    },
  );

  const filterInput = (ev) => {
    if (['e', 'E', ' ', '-'].includes(ev.key)) ev.preventDefault();
  };

  return (
    <div className="bvs-form-group">
      <input
        {...input}
        id={input.name}
        type={input.type}
        className={inputFieldClassNames}
        autoCapitalize="off"
        autoCorrect="off"
        onKeyPress={filterInput}
      />
      <PlaceHolder label={label} htmlFor={input.name} />
      { showMessage(touched, error) }
    </div>
  );
};

BasicNumber.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
};

export default BasicNumber;
