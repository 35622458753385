import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useFormState } from 'react-final-form';
import { compose, isEmpty } from 'underscore';
import {
  getWizardSteps,
  getLastStepVisitedNumber,
} from 'Registration/selectors/registration_form';
import { setCurrentStep as setCurrentStepDucks } from 'Registration/ducks/form';
import Step from './step';

const Steps = ({
  activeSteps, currentStep, lastStepVisitedNumber, handleSubmit, setCurrentStep,
}) => {
  const state = useFormState();
  const {
    errors, validating: asyncValidating, submitFailed: hasSubmitErrors,
  } = state;

  const stepClick = (nextStep) => {
    if (nextStep.number < lastStepVisitedNumber) {
      setCurrentStep(nextStep);
    } else if (nextStep.number !== currentStep.number && !asyncValidating) {
      handleSubmit();
    }
  };

  return (
    <div className="regpath__steps-indicator">
      {
        activeSteps.map((step) => (
          <Step
            key={step.name}
            step={step}
            currentStep={currentStep}
            hasErrorsAfterSubmit={hasSubmitErrors && !isEmpty(errors)}
            onStepClick={stepClick}
          />
        ))
      }
    </div>
  );
};

Steps.propTypes = {
  currentStep: PropTypes.instanceOf(Object).isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  lastStepVisitedNumber: PropTypes.number.isRequired,
  activeSteps: PropTypes.instanceOf(Array).isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  ...state.registrationForm,
  lastStepVisitedNumber: getLastStepVisitedNumber(state),
  activeSteps: getWizardSteps(state),
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentStep: compose(dispatch, setCurrentStepDucks),
});

export default connect(mapStateToProps, mapDispatchToProps)(Steps);
