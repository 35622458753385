import { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'underscore';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  getCountries,
  getDefaultCountrySetting,
  getCountryZonesBySelectedCountry,
  getCountryCounties,
  getCountryStates,
} from 'Registration/selectors/registration_configurations';
import { setCurrentCountry } from 'Registration/ducks/form';
import { getDefaultCountryID } from 'Registration/helpers/countries_helpers';
import showMessage from '../basic_error';
import Placeholder from '../field_placeholder';

const AddressDropdown = ({
  input,
  label,
  countries,
  countryZones,
  meta: {
    touched, error, dirtySinceLastSubmit, submitError,
  },
  defaultCountrySetting,
  setCountry,
}) => {
  const setDefaulCountryValue = () => {
    if (countries.length === 1) input.onChange(countries[0].id);
    else {
      const defaultCountryID = getDefaultCountryID(countries, defaultCountrySetting);
      if (defaultCountryID) input.onChange(defaultCountryID);
    }
  };

  useEffect(() => {
    if (input.name === 'country') {
      if (!input.value) {
        setDefaulCountryValue();
      } else {
        const inputValue = parseInt(input.value, 10);
        if (!countries.find((c) => c.id === inputValue)) {
          setDefaulCountryValue();
        }
      }
    }
  }, []);

  const handleChange = (event) => {
    input.onChange(event.target.value);
    if (input.name === 'country') setCountry(event.target.value);
  };

  const renderItems = (items) => items.map((i) => (
    <option key={i.id} id={i.id} value={i.id}>{i.description}</option>
  ));

  const renderOptions = () => {
    switch (input.name) {
      case 'country':
        return renderItems(countries);
      case 'county_id':
      case 'state_id':
        return renderItems(countryZones);
      default:
        return null;
    }
  };

  const showError = (touched && error) || (!dirtySinceLastSubmit && submitError);

  return (
    <div className="bvs-form-group">
      {input.name === 'country' && countries.length === 1 ? (
        <input
          value={countries[0].description}
          type="text"
          className="bvs-form-control"
          readOnly
        />
      ) : (
        <>
          <select
            {...input}
            key={input.name}
            id={`account_${input.name}`}
            value={input.value || ''}
            onChange={(event) => {
              handleChange(event);
            }}
            className={classnames('bvs-form-control active', { error: showError })}
          >
            {!input.value && (
              <option value="">
                {label}
              </option>
            )}
            {renderOptions()}
          </select>
          {input.value && (
            <Placeholder
              label={label}
              htmlFor={`account_${input.name}`}
            />
          )}
          {showMessage(touched, error)}
        </>
      )}
    </div>
  );
};

AddressDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  countries: PropTypes.arrayOf(Object).isRequired,
  counties: PropTypes.arrayOf(Object).isRequired,
  states: PropTypes.arrayOf(Object).isRequired,
  defaultCountrySetting: PropTypes.string.isRequired,
  setCountry: PropTypes.func.isRequired,
  countryZones: PropTypes.arrayOf(Object).isRequired,
};

const mapStateToProps = (state) => ({
  countries: getCountries(state),
  defaultCountrySetting: getDefaultCountrySetting(state),
  countryZones: getCountryZonesBySelectedCountry(state),
  states: getCountryStates(state),
  counties: getCountryCounties(state),
});

const mapDispatchToProps = (dispatch) => ({
  setCountry: compose(dispatch, setCurrentCountry),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressDropdown);
