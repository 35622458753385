import { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Card } from 'bv-components';
import { t } from 'bv-i18n';
import Placeholder from './field_placeholder';
import showMessage from './basic_error';

const IdentityCardNumber = ({
  input, meta: {
    touched, error, active,
  },
}) => {
  const [showCardImage, setShowCardImage] = useState(false);

  const onInputFocus = () => {
    setShowCardImage(true);
    input.onFocus();
  };

  const onInputBlur = () => {
    // Required to allow a possible submit button click to be handled, otherwise won't work
    setTimeout(() => {
      input.onBlur();
      setShowCardImage(false);
    }, 100);
  };

  const inputFieldClassNames = classnames(
    'bvs-form-control', {
      error: error && touched,
      active,
      'with-value': !!(input.value),
    },
  );

  return (
    <Card>
      <div className="bvs-form-group">
        <input
          {...input}
          type="text"
          className={inputFieldClassNames}
          name="id_number"
          id="id_number"
          onFocus={onInputFocus}
          onBlur={onInputBlur}
        />
        <Placeholder label={t('javascript.account_form.id_number')} htmlFor="id_number" />
        { showMessage(touched, error) }
      </div>
      { showCardImage && <div className="regpath__tooltip-image idcard-number" /> }
    </Card>
  );
};

IdentityCardNumber.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
};

export default IdentityCardNumber;
