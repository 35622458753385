import PropTypes from 'prop-types';

const AddressOptions = ({
  onAddressOptionClick, addressOptions,
}) => {
  const onAddressOptionMouseDown = (event, opt) => {
    event.preventDefault();
    onAddressOptionClick(opt);
  };

  if (!addressOptions.length) return null;

  return (
    <div className="regpath__address-finder-options-container">
      <div className="address-options-container">
        <ul className="address-options-list">
          {addressOptions.map((option) => (
            <li
              key={option.Id}
              className="address-option"
              onMouseDown={(evt) => onAddressOptionMouseDown(evt, option)}
            >
              <span className="option-text">{`${option.Text}`}</span>
              <span className="option-description">
                {option.Description}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

AddressOptions.propTypes = {
  onAddressOptionClick: PropTypes.func.isRequired,
  addressOptions: PropTypes.arrayOf(Object),
};

AddressOptions.defaultProps = {
  addressOptions: [],
};

export default AddressOptions;
