import PropTypes from 'prop-types';

import AppContainer from './components/app/app_container';
import configurationsReducer from './ducks/configurations';
import formReducer from './ducks/form';

const { addReducers } = window.reduxState;

addReducers({
  registrationConfigurations: configurationsReducer,
  registrationForm: formReducer,
});

const RegistrationApp = ({ kind }) => (
  <AppContainer formKind={kind} />
);

RegistrationApp.propTypes = {
  kind: PropTypes.string,
};

RegistrationApp.defaultProps = {
  kind: 'registration',
};

export default RegistrationApp;
