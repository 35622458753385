import { t } from 'bv-i18n';

const day = (value, text) => (
  { value: `0${value}`.slice(-2), text }
);

const item = (value, text) => ({ value, text });

const dayLabel = [item('', t('day'))];
const monthLabel = [item('', t('month'))];
const yearLabel = [item('', t('year'))];

// Days
const partialDays = Array(31).fill().map((x, i) => day(i + 1, i + 1));
export const days = dayLabel.concat(partialDays);

// Months
const partialMonths = [
  item('01', t('january')),
  item('02', t('february')),
  item('03', t('march')),
  item('04', t('april')),
  item('05', t('may')),
  item('06', t('june')),
  item('07', t('july')),
  item('08', t('august')),
  item('09', t('september')),
  item('10', t('october')),
  item('11', t('november')),
  item('12', t('december')),
];
export const months = monthLabel.concat(partialMonths);

// Years
const startDate = 1911;
// The most recent possible year should be 18 years ago, which is lowest age possible
// Since the array starts on zero index, we should add one more year:
const endDate = new Date().getFullYear() - 17;

const partialYears = Array(endDate - startDate).fill().map((x, i) => (
  item(i + startDate, i + startDate)
)).reverse();
export const years = yearLabel.concat(partialYears);
