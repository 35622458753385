import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { debounce } from 'underscore';
import { t } from 'bv-i18n';
import { v as bvVar } from 'bv';
import { transformAddress, findAddress, retrieveAddress } from 'Registration/api/address_finder';
import { getCountryIso } from 'Registration/selectors/registration_configurations';
import { getCurrentCountry } from 'Registration/selectors/registration_form';
import FormField from 'Registration/components/form_kinds/registration/form_step/form_field';
import AddressOptions from './address_options';

const AddressFinderContainer = ({
  renderManualAddress,
  changeInput,
}) => {
  const countryId = useSelector(getCurrentCountry);
  const countryIso = useSelector(getCountryIso);

  const [addressOptions, setAddressOptions] = useState([]);
  const [hasAddressControlError, setHasAddressControlError] = useState(false);
  const [showAddressOptions, setShowAddressOptions] = useState(false);
  const [searchText, setSearchText] = useState('');

  const renderError = () => {
    setHasAddressControlError(true);
    setAddressOptions([]);
  };

  const searchAddress = (text) => {
    setHasAddressControlError(false);
    if (text) {
      findAddress(text, countryIso).then((response) => {
        if (response.error) renderError();
        else {
          setAddressOptions(response.items);
          setShowAddressOptions(true);
        }
      });
    }
  };

  const debounceAddressFind = useCallback(debounce(searchAddress, bvVar('loqateAddressFinderDebounce') || 150), []);

  useEffect(() => {
    debounceAddressFind(searchText);
  }, [searchText]);

  const onAddressOptionClick = (option) => {
    setHasAddressControlError(false);
    if (option.Type === 'Address') {
      retrieveAddress(option.Id).then((result) => {
        const address = result.items[0];
        setAddressOptions([]);
        setShowAddressOptions(false);
        transformAddress(countryId, address).then((addressFields) => {
          Object.entries(addressFields).forEach(([key, value]) => {
            changeInput(key, value);
          });
          renderManualAddress({ clearData: false });
        });
      });
    } else {
      findAddress(option.Text, countryIso, option.Id).then((response) => {
        if (response.error) renderError();
        else setAddressOptions(response.items);
      });
    }
  };

  return (
    <>
      <FormField
        key="address_finder"
        name="address_finder"
        label="address_finder"
        type="address_finder"
        validations={[{
          type: 'mandatory',
          errorMessage: t('javascript.account_form.errors.mandatory.address'),
        }]}
        hasAddressControlError={hasAddressControlError}
        onBlur={() => setShowAddressOptions(false)}
        onFocus={() => setShowAddressOptions(true)}
        onChange={(e) => setSearchText(e.target.value)}
      />
      {showAddressOptions && (
        <AddressOptions
          onAddressOptionClick={onAddressOptionClick}
          addressOptions={addressOptions}
        />
      )}
      <div className="cta__wrapper">
        <span className="account-form__field-text cta__text">
          { t('javascript.account_form.enter_address_manually') }
        </span>
        <span
          className="bvs-button-card cta__btn"
          onClick={() => renderManualAddress({ clearData: true })}
        >
          { t('javascript.account_form.enter_address_manually_cta') }
        </span>
      </div>
    </>
  );
};

AddressFinderContainer.propTypes = {
  renderManualAddress: PropTypes.func.isRequired,
  changeInput: PropTypes.func.isRequired,
};

export default AddressFinderContainer;
