import { useMemo } from 'react';
import { useFormState } from 'react-final-form';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { escapeInput } from 'Registration/helpers/validations';
import { getEmailInUse } from 'Registration/helpers/form';
import PlaceHolder from './field_placeholder';
import showMessage from './basic_error';

const Email = ({
  input, label, meta: {
    error, active, touched, dirtySinceLastSubmit,
  },
}) => {
  const { values, submitErrors } = useFormState();
  const emailInUse = getEmailInUse({ ...values, email: input.value });
  const submitError = submitErrors && submitErrors[input.name];
  const showError = touched || submitError;

  const fieldError = useMemo(
    () => error || (!dirtySinceLastSubmit && submitError),
    [error, submitError, dirtySinceLastSubmit],
  );

  const inputFieldClassNames = classnames(
    'bvs-form-control', {
      error: fieldError && showError && !emailInUse,
      active,
      'with-value': input.value,
    },
  );

  const onChange = ({ target: { value } }) => input.onChange(escapeInput(value));

  const onBlur = () => setTimeout(() => input.onBlur(input.value), 100);

  return (
    <div className="bvs-form-group">
      <input
        {...input}
        type={input.type}
        className={inputFieldClassNames}
        id="regpath_form_email_input"
        autoCapitalize="off"
        autoCorrect="off"
        autoComplete="off"
        onChange={onChange}
        onBlur={onBlur}
      />
      <PlaceHolder label={label} htmlFor="regpath_form_email_input" />
      {!active && !emailInUse && showMessage(showError, fieldError)}
      <div className="g-recaptcha" />
    </div>
  );
};

Email.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
};

export default Email;
