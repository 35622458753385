import PropTypes from 'prop-types';
import classnames from 'classnames';
import { t } from 'bv-i18n';
import showMessage from './basic_error';

const AddressFinder = ({
  meta: { active, touched, error }, hasAddressControlError, onChange, onBlur, onFocus,
}) => {
  const inputFieldClassNames = classnames(
    'bvs-form-control', {
      error: (error && touched) || hasAddressControlError,
      active,
    },
  );

  const showError = () => {
    if (!active && !hasAddressControlError) return showMessage(touched, error);
    if (hasAddressControlError) {
      return (
        <span className="bvs-form-error-msg">
          {t('javascript.account_form.address_finder_unavailable')}
        </span>
      );
    }
    return null;
  };

  return (
    <div className="bvs-form-group">
      <input
        className={inputFieldClassNames}
        type="text"
        autoComplete="off"
        maxLength="100"
        id="address_search"
        placeholder={t('javascript.account_form.auto_address_finder')}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      {showError()}
    </div>
  );
};

AddressFinder.propTypes = {
  meta: PropTypes.instanceOf(Object).isRequired,
  hasAddressControlError: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

AddressFinder.defaultProps = {
  hasAddressControlError: false,
  onChange: null,
  onBlur: null,
  onFocus: null,
};

export default AddressFinder;
