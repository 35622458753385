import { connect } from 'react-redux';
import { compose } from 'underscore';

import { getWizardSteps } from 'Registration/selectors/registration_form';
import { setCurrentStep as setCurrentStepDucks } from 'Registration/ducks/form';

import StepsView from './steps_view';

const mapStateToProps = (state) => {
  const { currentStep } = state.registrationForm;
  const steps = getWizardSteps(state);

  return {
    numberOfSteps: steps.length,
    currentStepNumber: currentStep.number,
    previousStep: steps[steps.indexOf(currentStep) - 1],
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCurrentStep: compose(dispatch, setCurrentStepDucks),
});

export default connect(mapStateToProps, mapDispatchToProps)(StepsView);
