import PropTypes from 'prop-types';
import classnames from 'classnames';

const getSelectClassName = (error) => (
  classnames('bvs-form-control', { error })
);

const SelectList = ({
  name, items, value, onChange, error,
}) => (
  <div className="bvs-form-group">
    <select value={value} name={name} className={getSelectClassName(error)} onChange={onChange}>
      { items.map((item) => <option key={item.value} value={item.value}>{item.text}</option>) }
    </select>
  </div>
);

SelectList.propTypes = {
  name: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(Object).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
};

SelectList.defaultProps = {
  error: null,
};

export default SelectList;
