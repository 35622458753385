import { Card } from 'bv-components';
import { t } from 'bv-i18n';

const Error = () => (
  <Card>
    <p>{t('javascript.account.errors.generic_error')}</p>
  </Card>
);

export default Error;
