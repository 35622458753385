import PropTypes from 'prop-types';

const Placeholder = ({ label, htmlFor }) => (
  <label className="bvs-form-control-placeholder" htmlFor={htmlFor}>{label}</label>
);

Placeholder.propTypes = {
  label: PropTypes.string.isRequired,
  htmlFor: PropTypes.string.isRequired,
};

export default Placeholder;
