import { t } from 'bv-i18n';

export const showErrorMessage = (message) => {
  window.ModalHelper.showDangerMessage({
    title: t('javascript.error_page.error'),
    message: message || t('something_went_wrong_try_again'),
    iconClosable: true,
    icon: false,
    dataLayer: {
      error_title: t('javascript.error_page.error'),
      error_message: message || t('something_went_wrong_try_again'),
      error_key: message || 'something_went_wrong_try_again',
      error_code: 'E0x003',
    },
  });
};
