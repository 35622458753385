import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { accountData } from 'bv';
import withRegModal from 'Registration/hocs/with_reg_modal';

import withLazyLoad from 'bv-lazy-load';
import { Spinner } from 'bv-components';

const Offers = withLazyLoad(<Spinner />)('offers');

const BonusSelectionView = ({
  currentWizard, navigateToDeposit, optInHandler, setShowBack,
}) => {
  const history = useHistory();
  const [account, setAccount] = useState(null);

  useEffect(() => {
    // TODO: Temporary hack until make registration path more react router friendly
    history.replace('/account/offers?first_modal=true');
    accountData().then(setAccount);
  }, [currentWizard]);

  if (!account) return <Spinner />;

  const config = {
    userName: account.first_name || '',
    showBackButton: false,
    optInHandler,
    navigateToDeposit,
    // We have to do this due to offers having their own router
    // And better not to touch offers for now
    // Once we have only 1 router it should be less hacky
    // Probably the signup offers component should be the one with the ModalWindow
    // Instead of being created here
    offersList: () => { setShowBack(false); },
    offerDetail: () => { setShowBack(true); },
  };

  return (
    <Offers id="signup" config={config} />
  );
};

BonusSelectionView.propTypes = {
  currentWizard: PropTypes.instanceOf(Object).isRequired,
  optInHandler: PropTypes.func.isRequired,
  navigateToDeposit: PropTypes.func.isRequired,
  setShowBack: PropTypes.func.isRequired,
};

export default withRegModal('account_created')(BonusSelectionView);
