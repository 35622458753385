import Password from 'SharedComponents/password_with_error_list';
import { useSelector } from 'react-redux';
import { getPasswordRules } from 'Registration/selectors/registration_configurations';

export default (props) => (
  <Password
    rules={useSelector(getPasswordRules)}
    {...props}
  />
);
