import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ModalWindow, SiteFooter } from 'bv-components';
import { t } from 'bv-i18n';

import FooterGermany from '../components/form_parts/footer_germany';

export default (tKey) => (WrappedComponent) => {
  const RegModal = (props) => {
    const icons = useSelector((state) => state
      .registrationConfigurations
      ?.settings
      ?.footerPaymentIcons);

    useEffect(() => {
      document.body.classList.add('smartbanner--hide');

      return () => { document.body.classList.remove('smartbanner--hide'); };
    }, []);

    const [showBack, setShowBack] = useState(false);
    const { onClose, onBack, confirmClose } = props;

    return (
      <ModalWindow
        title={t(`javascript.account_form.form_titles.${tKey}`)}
        className="registration-modal"
        showBack={showBack}
        onClose={onClose}
        onBack={onBack}
        confirmClose={confirmClose}
      >
        <>
          <WrappedComponent {...props} setShowBack={setShowBack} />
          {icons === null
            ? (
              <SiteFooter
                className="modal_footer"
                tagName="div"
                target="_blank"
                isRegPath
              />
            )
            : <FooterGermany icons={icons} />}
        </>
      </ModalWindow>
    );
  };

  RegModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    confirmClose: PropTypes.instanceOf(Object).isRequired,
  };

  return RegModal;
};
