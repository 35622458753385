import PropTypes from 'prop-types';

import RegistrationContainer from 'Registration/components/form_kinds/registration/registration_container';
import BonusSelectionContainer from 'Registration/components/form_kinds/bonus_selection/bonus_selection_container';
import CashierContainer from 'Registration/components/form_kinds/cashier/cashier_container';

const kindToComponentMap = {
  registration: RegistrationContainer,
  bonus_selection: BonusSelectionContainer,
  cashier: CashierContainer,
};

const FormKind = ({ kind }) => {
  const Component = kindToComponentMap[kind];

  return Component ? <Component /> : null;
};

FormKind.propTypes = {
  kind: PropTypes.string.isRequired,
};

export default FormKind;
