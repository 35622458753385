import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormState, useField } from 'react-final-form';
import { connect } from 'react-redux';
import { Card } from 'bv-components';
import {
  getDefaults,
  getCurrencies,
  getCurrencySettings,
  getFilteredCurrencies,
  getFirstCurrency,
  getSecondCurrency,
} from 'Registration/selectors/registration_configurations';
import Currency from './currency';
import CurrentCurrency from './current_currency';
import OtherIcon from './other_icon';
import CurrencyModal from './currency_modal';

const CurrencyField = ({
  label, currencies, defaults,
  filteredCurrencies, firstCurrency, secondCurrency,
}) => {
  const { values } = useFormState();
  const { input } = useField('currency');
  // Number of currencies that should be shown, not counting with other icon
  const currenciesToDisplay = 2;
  const [showMoreIcon, setShowMoreIcon] = useState(true);
  const [showOthersModal, setShowOthersModal] = useState(false);

  useEffect(() => {
    const defaultCurrency = firstCurrency?.id || parseInt(defaults.currency, 10);
    if (defaultCurrency && !values.currency) {
      input.onChange(defaultCurrency);
    }
    setShowMoreIcon(!defaultCurrency && (input.value === undefined || input.value === ''));
  }, [firstCurrency]);

  const onCurrencyModalClick = (currencyID) => {
    input.onChange(currencyID);
    setShowOthersModal(false);
    setShowMoreIcon(false);
  };

  const currencyClick = (currencyID) => {
    input.onChange(currencyID);
    setShowMoreIcon(true);
  };

  const shouldShowMoreIcon = () => {
    if (showMoreIcon && currencies.length > currenciesToDisplay + 1) return true;

    // Check if selected currency is one of the showns before "other" icon
    // In this case, other icon should be shown in order to not duplicate currency
    const inputCurrency = parseInt(input.value, 10);
    return (firstCurrency?.id === inputCurrency) || (secondCurrency?.id === inputCurrency);
  };

  if (firstCurrency && secondCurrency === undefined) return null;

  return (
    <Card>
      <p className="account-form__field-title reg-path__title-own-card">{label}</p>
      <div className="account-form__circle-wrapper">
        { firstCurrency && (
          <Currency
            {...firstCurrency}
            currentCurrency={input.value}
            onClickHandler={currencyClick}
          />
        )}
        { secondCurrency && (
          <Currency
            {...secondCurrency}
            currentCurrency={input.value}
            onClickHandler={currencyClick}
          />
        )}
        { currencies.length > currenciesToDisplay && (shouldShowMoreIcon()
          ? <OtherIcon onClickHandler={() => setShowOthersModal(true)} />
          : (
            <CurrentCurrency
              currencies={currencies}
              input={input}
              onClickHandler={() => setShowOthersModal(true)}
            />
          ))}
      </div>
      {currencies.length > currenciesToDisplay && showOthersModal && (
        <CurrencyModal
          filteredCurrencies={filteredCurrencies}
          onClose={() => setShowOthersModal(false)}
          onCurrencyClick={onCurrencyModalClick}
        />
      )}
    </Card>
  );
};

CurrencyField.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  currencies: PropTypes.arrayOf(Object).isRequired,
  defaults: PropTypes.instanceOf(Object).isRequired,
  currencySettings: PropTypes.arrayOf(Object).isRequired,
  filteredCurrencies: PropTypes.arrayOf(Object).isRequired,
  firstCurrency: PropTypes.instanceOf(Object).isRequired,
  secondCurrency: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = (state) => ({
  defaults: getDefaults(state),
  currencySettings: getCurrencySettings(state),
  filteredCurrencies: getFilteredCurrencies(state),
  firstCurrency: getFirstCurrency(state),
  secondCurrency: getSecondCurrency(state),
  currencies: getCurrencies(state),
});

export default connect(mapStateToProps)(CurrencyField);
