const getCountryId = (countries, countryIsoCode) => (
  countries.find(({ isoCode }) => isoCode === countryIsoCode)?.id
);

export default (oAuthParams, countries) => {
  const { identity, identity_provider: identityProvider } = oAuthParams;

  if (!identity) return {};

  const {
    country_iso_code: countryIsoCode,
    ...rest
  } = identity;

  return {
    country: getCountryId(countries, countryIsoCode),
    identity_provider: identityProvider,
    ...rest,
  };
};
