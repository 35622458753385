import { useEffect, useRef } from 'react';
import { useFormState } from 'react-final-form';
import { Link } from 'bv-components';
import { getEmailInUse } from 'Registration/helpers/form';
import { t } from 'bv-i18n';

const AccountExist = () => {
  const { values } = useFormState();
  const emailInUse = getEmailInUse(values);

  const elRef = useRef(null);

  useEffect(() => {
    if (elRef?.current) {
      elRef.current.scrollIntoView();
    }
  }, []);

  const onClickLink = () => localStorage.setItem('email_in_use', emailInUse);

  return (
    <div className="account-form__email-in-use" ref={elRef}>
      <div className="account-form__email-in-use-title">{t('is_your_account')}</div>
      <Link to="/login" className="bvs-button-card" onClick={onClickLink} data-react>
        {t('login_now')}
      </Link>
      <Link to="/account/password_reset" className="bvs-button-card" onClick={onClickLink} data-react>
        {t('recover_account')}
      </Link>
    </div>
  );
};

export default AccountExist;
