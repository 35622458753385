import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { t } from 'bv-i18n';
import { isHCaptcha } from 'bv-services';
import { selectHCaptchaCompleted } from 'SharedComponents/hcaptcha/selectors';

const SubmitButton = () => {
  const { submitting } = useFormState();
  const hCaptchaCompleted = useSelector(selectHCaptchaCompleted);

  return (
    <button
      type="submit"
      className="account-form__submit-button"
      disabled={submitting || (isHCaptcha && !hCaptchaCompleted)}
    >
      {t('javascript.account_form.action_buttons.create_account')}
    </button>
  );
};

export default SubmitButton;
