import { postJSON, getJSON } from 'bv-fetch';

export const findAddress = (text, countryCode, container) => (
  getJSON('/bv_api/account/address_find', {
    country_iso: countryCode,
    container,
    text,
  })
);

export const retrieveAddress = (addressId) => (
  getJSON('/bv_api/account/address_retrieve', {
    address_id: addressId,
  })
);

export const transformAddress = (countryId, addressFinderResult) => (
  postJSON(`/api/address_finder/${countryId}/prefill`, { data: addressFinderResult })
);
