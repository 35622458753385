import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { compose } from 'underscore';

import { setNextWizard as setNextWizardAction } from 'Registration/ducks/form';
import { getAllWizards, getCurrentWizard } from 'Registration/selectors/registration_form';
import BonusSelectionView from './bonus_selection_view';

const { showDangerMessage } = window.ModalHelper;

const BonusSelectionContainer = ({
  allWizards, setNextWizard, ...props
}) => {
  const navigateToDeposit = () => {
    setNextWizard(allWizards);
  };

  const moveToDeposit = (response) => {
    const failure = () => (response?.code && response?.description ? response.description : t('javascript.promo_hub.opt_in.failed'));

    if (!response || !response.success) {
      showDangerMessage({
        onClose: navigateToDeposit,
        message: failure(),
        actions: [{ label: t('javascript.close'), danger: true, close: true }],
        dataLayer: {
          error_message: failure(),
          error_key: failure(),
          error_code: 'E0x015',
        },
      });
    } else {
      navigateToDeposit();
    }
  };
  return (
    <BonusSelectionView
      {...props}
      navigateToDeposit={navigateToDeposit}
      optInHandler={moveToDeposit}
      onClose={navigateToDeposit}
    />
  );
};

BonusSelectionContainer.propTypes = {
  allWizards: PropTypes.instanceOf(Object).isRequired,
  setNextWizard: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  allWizards: getAllWizards(state),
  currentWizard: getCurrentWizard(state),
});

const mapDispatchToProps = (dispatch) => ({
  setNextWizard: compose(dispatch, setNextWizardAction),
});

export default connect(mapStateToProps, mapDispatchToProps)(BonusSelectionContainer);
