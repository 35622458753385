import PropTypes from 'prop-types';
import classnames from 'classnames';

const Currency = ({
  id, currentCurrency, description, iso_code: isoCode, onClickHandler,
}) => {
  const handler = () => onClickHandler(id);

  const getClassNames = () => classnames('account-form__circle-symbol', {
    active: parseInt(currentCurrency, 10) === id,
  });

  return (
    <div className="account-form__circle-selector">
      <span className="account-form__circle-label">{description}</span>
      <span className={getClassNames()} onClick={handler}>{isoCode}</span>
    </div>
  );
};

Currency.propTypes = {
  id: PropTypes.number.isRequired,
  currentCurrency: PropTypes.string,
  description: PropTypes.string.isRequired,
  iso_code: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func,
};

Currency.defaultProps = {
  currentCurrency: undefined,
  onClickHandler: () => {},
};

export default Currency;
