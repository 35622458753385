import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose, isEmpty } from 'underscore';
import PropTypes from 'prop-types';
import { destroyCaptchaServices, loadCaptchaServices } from 'bv-services';
import bootFormSv from 'Registration/services/boot_form';
import { getFeatures, getLoading } from 'Registration/selectors/registration_configurations';
import { getCurrentWizard } from 'Registration/selectors/registration_form';
import { loadRequiredDependencies, loadFeatureFlipperDependencies } from 'Registration/helpers/dependencies';
import FormKind from 'Registration/factories/form_kind';
import PreviewApp from './preview_app';

const AppContainer = ({
  bootForm, loading, currentWizard, formKind, features,
}) => {
  const [dependenciesLoaded, setDependenciesLoaded] = useState(false);

  useEffect(() => {
    bootForm(formKind);
    document.documentElement.classList.add('signup-view-launched');

    return () => {
      document.documentElement.classList.remove('signup-view-launched');
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(features)) {
      // Load reg path dependencies based on CMS settings
      Promise.all([
        ...loadRequiredDependencies(features),
        ...loadFeatureFlipperDependencies(),
        loadCaptchaServices(),
      ])
        .then(() => setDependenciesLoaded(true));
      return destroyCaptchaServices;
    }
    return undefined;
  }, [features]);

  if (loading || !currentWizard) return <PreviewApp {...{ loading, currentWizard }} />;

  if (!dependenciesLoaded) return <PreviewApp loading />;

  return <FormKind kind={currentWizard.kind} />;
};

AppContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  currentWizard: PropTypes.func.isRequired,
  bootForm: PropTypes.func.isRequired,
  formKind: PropTypes.string,
  features: PropTypes.instanceOf(Object).isRequired,
};

AppContainer.defaultProps = {
  formKind: 'registration',
};

const mapStateToProps = (state) => ({
  loading: getLoading(state),
  currentWizard: getCurrentWizard(state),
  features: getFeatures(state),
});

const mapDispatchToProps = (dispatch) => ({
  bootForm: compose(dispatch, bootFormSv),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
