import { v as bvVar } from 'bv';
import { t, locale } from 'bv-i18n';
import { getCountryByCode as getPhoneCountryByCode } from 'registration-common/helpers/phone_number_utils';
import { getCountryByCode } from './countries_helpers';
import { validateAddress } from '../services/address_validator';

const detailsFieldsMap = [
  ['first_name', 'firstName'],
  ['last_name', 'lastName'],
  ['email', 'email'],
];

const addressFieldsMap = bvVar('addressFieldsMap');

const localeCodes = locale().split('-');

export const payPalSettings = {
  flow: 'vault',
  commit: false,
  intent: 'tokenize',
  billingAgreementDescription: t('paypal_description_message', { brand_name: bvVar('siteName') }),
  displayName: bvVar('siteName'),
  locale: `${localeCodes[0]}_${localeCodes[1].toUpperCase()}`,
};

const getCountyId = (countryId, countryZones, region) => {
  if (!countryId || !countryZones || !region || !countryZones[countryId.toString()]) {
    return undefined;
  }

  return countryZones[countryId.toString()].find((zone) => (
    zone.description.toLowerCase() === region.toLowerCase()
  ))?.id;
};

export const fillRegistratonFields = (
  changeInput, details, countries, phoneNumberCountries, countryZones, loqateValidatorApiKey,
) => {
  if (details) {
    detailsFieldsMap.forEach((f) => changeInput(f[0], details[f[1]]));

    const shortLocale = locale().split('-')[1].toLowerCase();
    changeInput('phone_number', {
      number: details?.phone?.replaceAll('-', '')?.replaceAll(' ', ''),
      country_iso: details.countryCode?.toLowerCase() || shortLocale,
      dial_code: (getPhoneCountryByCode(
        phoneNumberCountries,
        details.countryCode || shortLocale,
      )?.dialCode),
    });

    if (details.billingAddress) {
      validateAddress(details.billingAddress, loqateValidatorApiKey).then((billingAddress) => {
        const countryId = getCountryByCode(countries, billingAddress.countryCode || '')?.id;
        changeInput('country', countryId);

        const countyId = getCountyId(countryId, countryZones, billingAddress.state);
        if (countyId !== undefined) {
          changeInput('county', countyId);
          changeInput('countriesWithCounty', [countryId.toString()]);
        } else {
          changeInput('county', billingAddress.state);
        }

        addressFieldsMap.forEach((f) => changeInput(f[0], billingAddress[f[1]]));
      });
    }
  }
};
