import PropTypes from 'prop-types';
import { Icon } from 'bv-components';
import { t } from 'bv-i18n';

const StepsView = ({
  numberOfSteps,
  currentStepNumber,
  setCurrentStep,
  previousStep,
}) => (
  <div className="regpath__steps-info-wrapper">
    <div className="regpath__back-button" onClick={() => { setCurrentStep(previousStep); }}>
      <Icon id="arrow-left" type="regpath-back-arrow" />
      {t('javascript.account_form.step_back')}
    </div>
    <div className="regpath__steps-info">
      {t('javascript.account_form.step_info', { current_step_number: currentStepNumber, number_of_steps: numberOfSteps })}
    </div>
  </div>
);

StepsView.propTypes = {
  numberOfSteps: PropTypes.number.isRequired,
  currentStepNumber: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  previousStep: PropTypes.instanceOf(Object).isRequired,
};

export default StepsView;
