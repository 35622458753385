import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'underscore';
import { getCurrentStepFields } from 'Registration/selectors/registration_form';
import { getFeatures, getCountries } from 'Registration/selectors/registration_configurations';
import { resetOneAuthParams as resetOneAuthParamsAction } from 'SharedComponents/one_auth/ducks/one_auth_params';
import { setCurrentCountry } from 'Registration/ducks/form';
import { setFormValues } from 'Registration/local_storage';
import FormStepView from './form_step_view';

const FormStepRedux = (props) => {
  const dispatch = useDispatch();

  const fields = useSelector(getCurrentStepFields);
  const features = useSelector(getFeatures);
  const countries = useSelector(getCountries);
  const oneAuthParams = useSelector((state) => state.registrationOneAuthParams || {});

  const resetOneAuthParams = compose(dispatch, resetOneAuthParamsAction);
  const setCountry = compose(dispatch, setCurrentCountry);

  return (
    <FormStepView
      fields={fields}
      features={features}
      countries={countries}
      oneAuthParams={oneAuthParams}
      resetOneAuthParams={resetOneAuthParams}
      setCountry={setCountry}
      onChange={setFormValues}
      {...props}
    />
  );
};

FormStepRedux.propTypes = {
  values: PropTypes.arrayOf(Object).isRequired,
};

export default FormStepRedux;
