import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isEmpty } from 'underscore';
import { t } from 'bv-i18n';
import showMessage from 'Registration/components/fields/basic_error';
import { renderBodyText } from 'Registration/helpers/text_utils';

const BaseConditionBox = ({
  input,
  label,
  meta: { touched, error },
  text,
  body,
}) => {
  const checkboxStyles = classnames('account-form__checkbox', {
    active: input.value === true,
  });

  const conditionStyles = classnames('bvs-checkbox-card account-form__condition-checkbox', {
    error: touched && !isEmpty(error),
  });

  const contentStyles = classnames('bvs-checkbox-card__content', {
    error: touched && !isEmpty(error),
  });

  return (
    <div className={`regpath__condition-box-wrapper regpath__${input.type}`}>
      <div className={conditionStyles}>
        <div className={contentStyles}>
          <p className="bvs-checkbox-card__title">{label}</p>
          <div>
            {body || renderBodyText(t(text || `javascript.account_form.${input.name}_description`))}
          </div>
        </div>
        <div className="bvs-checkbox-card__checkbox">
          <label htmlFor={input.name} className={checkboxStyles}>
            <input {...input} type="checkbox" className="account-form__hidden" id={input.name} />
          </label>
        </div>
      </div>
      { showMessage(touched, error) }
    </div>
  );
};

BaseConditionBox.propTypes = {
  label: PropTypes.string.isRequired,
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  text: PropTypes.string,
  body: PropTypes.element,
};

BaseConditionBox.defaultProps = {
  text: undefined,
  body: null,
};

export default BaseConditionBox;
