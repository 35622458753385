// THIS IS OUR CUSTOM DATA FOR THE FORM (WIZARD CONFIG, STEPS CONFIG, ....)
import { createSelector } from 'reselect';
import { flatten } from 'underscore';

// Fields that are slated to be removed from the Reg Config thus should not be relied upon
const DEPRECATED_FIELDS = ['country', 'address1', 'address2', 'city', 'county', 'postcode', 'address_finder', 'search_again'];

const getRegistrationForm = (state) => state.registrationForm || {};
const getRegistrationConfigurations = (state) => state.registrationConfigurations || {};
const getWizard = (state) => getRegistrationForm(state).currentWizard || {};

export const getWizardSteps = (state) => getWizard(state).steps || [];

export const getCurrentStep = createSelector(
  getRegistrationForm,
  (registrationForm) => registrationForm.currentStep,
);

export const getCurrentCountry = createSelector(
  getRegistrationForm,
  (registrationForm) => (
    parseInt(registrationForm.currentCountry, 10)
  ),
);

export const getManualAddressFields = createSelector(
  getCurrentStep,
  (currentStep) => currentStep.fields.filter((f) => f.featureGroup === 'manual_address'),
);

export const getCurrentJourney = createSelector(
  getRegistrationForm,
  (registrationForm) => registrationForm.currentJourney,
);

export const getCurrentWizard = createSelector(
  getRegistrationForm,
  (registrationForm) => registrationForm.currentWizard,
);

export const getAllWizards = createSelector(
  getRegistrationConfigurations,
  (registrationConfigurations) => registrationConfigurations.wizards,
);

export const getLastStepVisitedNumber = createSelector(
  getRegistrationForm,
  (registrationForm) => registrationForm.lastStepVisitedNumber || 1,
);

export const getCurrentStepFields = createSelector(
  getCurrentStep,
  (step) => (
    step.fields.filter((field) => !DEPRECATED_FIELDS.includes(field.name))
  ),
);

export const getAllWizardFields = createSelector(
  getCurrentWizard,
  (currentWizard) => flatten(currentWizard.steps.map((step) => (
    step.fields.reduce((acc, field) => {
      acc.push(field.name);

      return acc;
    }, [])
  ))),
);
