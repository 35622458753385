import { useState, useEffect } from 'react';
import { useForm } from 'react-final-form';
import PropTypes from 'prop-types';
import { v as bvVar } from 'bv';
import { fetchConfiguration } from 'Registration/api/paypal_api';
import { payPalSettings, fillRegistratonFields } from 'Registration/helpers/quick_reg_helper';
import PayPalButtonView from './paypal_button_view';

const PayPalButtonContainer = ({
  countries, phoneNumberCountries, changeJourney, setShowPayPalButton,
  setPayPalReady, countryZones, settings,
}) => {
  const form = useForm();

  const [payPalLoaded, setPayPalLoaded] = useState(false);
  const onError = () => {
    changeJourney('standard', 'registration');
    setPayPalReady(true);
  };

  useEffect(() => {
    fetchConfiguration().then((payPalConfig) => {
      if (payPalConfig?.token) {
        window.braintree.client.create({
          authorization: payPalConfig.token,
        })
          .then((clientInstance) => window.braintree.paypalCheckout.create({
            client: clientInstance,
          }))
          .then((paypalCheckoutInstance) => paypalCheckoutInstance.loadPayPalSDK({
            vault: true,
            commit: false,
            intent: 'tokenize',
          }))
          .then((paypalCheckoutInstance) => window.paypal.Buttons({
            env: bvVar('quickRegistrationPaypalEnv'),
            style: {
              label: 'paypal',
              layout: 'horizontal',
              tagline: false,
            },
            createBillingAgreement: () => paypalCheckoutInstance.createPayment(payPalSettings),
            onApprove: (data) => {
              changeJourney('paypal', 'registration');
              setShowPayPalButton(false);
              return paypalCheckoutInstance.tokenizePayment(data, (err, payload) => {
                if (payload) {
                  const { nonce, details } = payload;
                  form.change('nonce', nonce);
                  fillRegistratonFields(
                    form.change,
                    details,
                    countries,
                    phoneNumberCountries,
                    countryZones,
                    settings.loqateValidatorApiKey,
                  );
                  localStorage.setItem('registration_type', 'paypal');
                }
              });
            },
            onError: () => onError(),
          })
            .render('#paypal-button'))
          .then(() => {
            setPayPalReady(true);
            setPayPalLoaded(true);
          })
          .catch(onError);
      } else {
        setPayPalReady(true);
      }
    });
  }, []);

  return (
    <PayPalButtonView
      payPalLoaded={payPalLoaded}
    />
  );
};

PayPalButtonContainer.propTypes = {
  countries: PropTypes.arrayOf(Object).isRequired,
  phoneNumberCountries: PropTypes.arrayOf(Object).isRequired,
  changeJourney: PropTypes.func.isRequired,
  setShowPayPalButton: PropTypes.func.isRequired,
  setPayPalReady: PropTypes.func.isRequired,
  countryZones: PropTypes.arrayOf(Object).isRequired,
  settings: PropTypes.instanceOf(Object).isRequired,
};

export default PayPalButtonContainer;
